import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFound = () => {
  const { t } = useTranslation('translation');
  return (
    <Layout>
      <h1>{t('notFound')}</h1>
    </Layout>
  );
};

export default NotFound;

export const Head = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  return (
    <SEO title={`${t('404')} - Giedeé Informatyka`}>
      <meta name="robots" content="noindex" />
    </SEO>
  );
};
